import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import { navLinks } from "../../config"

const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
  transition: all 0.3s ease-in-out;
  display: ${({ open }) => (open ? "block" : "none")};
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const StyledContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  outline: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  pointer-events: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};
  gap: 1rem;
  height: 100vh;
  min-width: 18rem;
  width: 55%;
  text-align: left;
  padding: 2rem;
  position: relative;
  right: 0;
  margin-left: auto;
  pointer-events: auto;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
  .nav-link {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    padding: 2rem 0 0.5rem 0;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    @media (hover: hover) {
      &::before {
        transition: 200ms ease-out;
        height: 0.1563rem;
        content: "";
        position: absolute;
        background-color: ${({ theme }) => theme.colors.primary};
        width: 0%;
        bottom: -0.125rem;
        left: 0;
      }
      &:hover::before {
        width: 100%;
      }
    }
  }
  .cta-btn {
    width: auto;
    height: auto;
    margin: 3rem auto 1.5rem auto;
    padding: 1rem 1.5rem;
    font-weight: 700;
    font-size: 1.5rem;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.background};
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.primary} 50%,
      ${({ theme }) => theme.colors.background} 50%
    );
    background-size: 205% 100%;
    background-position: right bottom;
    transition: all 0.1s ease-out;
    color: ${({ theme }) => theme.colors.primary};
    @media (hover: hover) {
      &:hover {
        background-position: left bottom;
        color: ${({ theme }) => theme.colors.background};
      }
    }
  }
`

const Sidebar = ({ open, setOpen }) => {
  const { menu, button } = navLinks
  const hideButton = button.visible === false
  const toggleOpen = () => setOpen(!open)
  return (
    <>
      <StyledContainer open={open} aria-hidden={!open} tabIndex={open ? 1 : -1}>
        <StyledNav>
          {menu.map(({ name, url }, key) => (
            <Link className="nav-link" key={key} to={url} onClick={toggleOpen}>
              {name}
            </Link>
          ))}
          {!hideButton &&
            (button.useFileName ? (
              <a
                className="cta-btn"
                href={`/${button.fileName}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={toggleOpen}
              >
                {button.name}
              </a>
            ) : (
              <Link className="cta-btn" to={button.url} onClick={toggleOpen}>
                {button.name}
              </Link>
            ))}
        </StyledNav>
      </StyledContainer>
      <StyledBackdrop open={open} onClick={toggleOpen} />
    </>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default Sidebar
