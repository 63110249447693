module.exports = {
  //-- SITE SETTINGS -----
  author: "Angus S.L.",
  siteTitle: "Angus S.L.",
  siteShortTitle: "Portfolio", // Used as logo text in header, footer, and splash screen
  siteDescription:
    "A frontend developer with full stack experience and a passion for polished web experiences.",
  siteUrl: "https://angussl.com/",
  siteLanguage: "en_US",
  siteIcon: "content/favicon.png", // Relative to gatsby-config file
  seoTitleSuffix: "Angus S.L.", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: false, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "", // e.g. UA-XXXXXX-X

  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#000000",
      secondary: "#9fddfe",
      tertiary: "#96F596",
      text: "#000000",
      subtext: "#555555",
      background: "#FFFFFF",
      card: "#F5F5F5",
      cardHover: "#EEEEEE",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.1)",
      boxShadowHover: "rgba(0, 0, 0, 0.2)",
      buttonHover: "#242E31",
      light: true,
    },
    darkTheme: {
      primary: "#FAFAFA",
      secondary: "#0881b5",
      tertiary: "#088508",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      card: "#1C1C1C",
      cardHover: "#222222",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.1)",
      boxShadowHover: "rgba(0, 0, 0, 0.2)",
      buttonHover: "#B8E6FF",
      dark: true,
    },
  },
  fonts: {
    primary: "Roboto, Arial, sans-serif",
  },

  //-- ARTICLES SECTION SETTINGS -----
  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed: "",
  // rssFeed: "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.theguardian.com%2Finternational%2Frss",

  shownArticles: 3,

  //-- SOCIAL MEDIA SETTINGS -----
  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, XING, Behance, E-Mail
  socialMedia: [],

  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [
      {
        name: "Skills",
        url: "/#skills",
      },
      {
        name: "Projects",
        url: "/#projects",
      },
      {
        name: "Contact",
        url: "/#contact",
      },
    ],
    button: {
      useFileName: true,
      name: "Resume",
      fileName: "Resume.Angus_Stien-Lehmann.pdf", // the file has to be placed inside the static folder at the root level
      url: "", // if useFileName=false, you can set an anchor link here and use the button for navigational purposes
      visible: false,
    },
  },
  footerLinks: [],
}
