import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { navLinks } from "../../config"

const StyledNav = styled.nav`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    gap: 4rem;
    background: ${({ theme }) => theme.colors.background};
    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  .nav-link {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    padding: 0;
    &::before {
      transition: 200ms ease-out;
      height: 0.1563rem;
      content: "";
      position: absolute;
      background-color: ${({ theme }) => theme.colors.primary};
      width: 0%;
      bottom: -0.125rem;
    }
    &:hover::before {
      width: 100%;
    }
  }
  .cta-btn {
    width: auto;
    height: auto;
    font-weight: 700;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    margin: 0;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.background};
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.primary} 50%,
      ${({ theme }) => theme.colors.background} 50%
    );
    background-size: 205% 100%;
    background-position: right bottom;
    transition: all 0.1s ease-out;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      background-position: left bottom;
      color: ${({ theme }) => theme.colors.background};
    }
  }
`

const Navbar = () => {
  const { menu, button } = navLinks
  const hideButton = button.visible === false
  return (
    <StyledNav>
      {menu.map(({ name, url }, key) => {
        return (
          <Link className="nav-link" key={key} to={url}>
            {name}
          </Link>
        )
      })}
      {!hideButton &&
        (button.useFileName ? (
          <a
            className="cta-btn"
            href={`/${button.fileName}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {button.name}
          </a>
        ) : (
          <Link className="cta-btn" to={button.url}>
            {button.name}
          </Link>
        ))}
    </StyledNav>
  )
}

export default Navbar
