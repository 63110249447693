import { createGlobalStyle } from "styled-components"
import modernNormalize from "styled-modern-normalize"

const GlobalStyle = createGlobalStyle`
    
    // Import normalize.css
    ${modernNormalize}

    * {
        box-sizing: border-box;
    }

    html {
        width: 100%;
        height: 100%;
    }

    body {
        --edge-padding: 1.5rem;
        --edge-padding-negative: -1.5rem;
        @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
            --edge-padding: 2.5rem;
            --edge-padding-negative: -2.5rem;
        }
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.text};
        font-size: 1rem;
        font-family: ${({ theme }) => theme.fonts.primary};
        line-height: 1.5rem;
        font-weight: 400;
        text-rendering: optimizeLegibility;
        &.blur {
            overflow: hidden;
            #___gatsby #main-content > * {
                filter: blur(5px) ;
                transition: all .3s ease-out;
                pointer-events: none;
                user-select: none;
            }
        }
    }

    &.splashScreen {
        position: fixed;
        overflow: hidden;
    }

    a {
        display: inline-block;
        text-decoration: none;
        text-decoration-skip-ink: auto;
        color: inherit;
        cursor: pointer;
        &:hover,
        &:focus {
            outline: 0;
        }
    }

    h1 {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 2.375rem;
        color: ${({ theme }) => theme.colors.primary};
        @media (min-width: ${({ theme }) => theme.breakpoints.micro}) {
            font-size: 1.4rem;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
            font-size: 1.64rem;
        }
        @media (min-width: 572px) {
            font-size: 1.9rem;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 2rem;
            line-height: 3rem;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
            font-size: 2.1rem;
        }
    }

    h2 {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.colors.primary};
        @media (min-width: ${({ theme }) => theme.breakpoints.micro}) {
            font-size: 1.25rem;
        }
        @media (min-width: 572px) {
            font-size: 1.35rem;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 1.5rem;
            line-height: 2rem;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
            font-size: 1.65rem;
        }
    }

    h3 {
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 2.25rem;
        color: ${({ theme }) => theme.colors.primary};
        margin-bottom: 2rem;
    }

    h4 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
    }

    hr {
        margin: 3rem auto;
        border-width: .05rem;
        color: ${({ theme }) => theme.colors.tertiary};
        opacity: 0.1;
    }

    .hero-subtitle {
        display: inline;
        position: absolute;
        text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
    }

    .isdark .hero-subtitle {
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    }

    #hero h1.title {
        position: relative;
        z-index: 2;
    }

    .hero-subtitle-container {
        display: inline;
        margin-left: .5rem;
    }

    .greetings-name {
        color: #0eb7eb;
        padding-left: .5rem;
    }

    .isdark .greetings-name {
        color: #00D1FF;
    }

    .portrait {
        max-width: 128px;
        max-height: 128px;
        min-width: 128px;
        border-radius: 100%;
    }

    .portrait-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        grid-area: portrait;
        max-width: 128px;
    }
    .avatar,
    .portrait {
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease-out;
        &:hover {
            transform: translate3d(0px, -0.125rem, 0px);
            box-shadow: 0 0 var(--edge-padding) rgba(0, 0, 0, 0.2);
        }
    }

    .hero-grid {
        display: grid;
        grid-template-areas:
            'portrait text'
            'blank buttons';
        grid-template-columns: 128px auto;
        column-gap: 2rem;
    }

    .social-buttons {
        grid-area: buttons;
    }

    p {
        line-height: 1.8rem;
    }
`

export default GlobalStyle
